//@import "../../../../styles/variables";
.validation-number-input,
.serial-number-input {
  width: 100%;
  height: 50px;
  &:focus {
    //border: 1px solid $--purple-500;
  }
  &::placeholder {
    color: #b4b4b4;
  }
}